import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function CountryFilter({countries, setCountries}) {

    const handleChange = (event) => {
      setCountries({ ...countries, region: event.target.value, api:'region'});
    };

    return (
        <div className="col colAuto">
            <FormControl sx={{ mb: 1, mt: 1, minWidth: 240 }}>
                <Select
                labelId="regionInput"
                id="regionSelect"
                displayEmpty
                value={countries.region}
                onChange={handleChange}
                >
                    <MenuItem disabled value="">Select region</MenuItem>
                    <MenuItem value="africa">Africa</MenuItem>
                    <MenuItem value="america">America</MenuItem>
                    <MenuItem value="asia">Asia</MenuItem>
                    <MenuItem value="europe">Europe</MenuItem>
                    <MenuItem value="oceania">Oceania</MenuItem>
                </Select>
            </FormControl>
        </div>
    );

}

export default CountryFilter;