import React from "react";
import CountryBorderCountries from "./CountryBorderCountries";
import Spinner from "../shared/Spinner";

const windowUrl = window.location.href;
const countryAlpha = getCountryString(windowUrl); 
const endpoint = 'https://restcountries.com/v2/alpha/'+countryAlpha;

function getCountryString(url) {
    let arr = url.split('/');
    return arr[arr.length-1];
}

function CountryDetailSkeleton() {

    const [country, setCountry] = React.useState(null);

    React.useEffect(() => {
        fetch(endpoint)
        .then(response => response.json())
        .then(data => setCountry(data));
    }, []);


    return country ? (
        
        <section className="countryDetail">
            <div className="row">
                <div className="col col5">
                    <img src={country.flag} alt={country.name} />
                </div>
                <div className="col col7">
                    <div className="countryDetailContent">
                        <h2>{country.name}</h2>
                        <div className="row countryDetailFacts">
                            <div className="col col6">
                                <p><strong>Native Name</strong>: {country.nativeName}</p>
                                <p><strong>Population</strong>: {country.population.toLocaleString('en-US')}</p>
                                <p><strong>Region</strong>: {country.region}</p>
                                <p><strong>Sub Region</strong>: {country.subregion}</p>
                                <p><strong>Capital</strong>: {country.capital}</p>
                            </div>
                            <div className="col col6">
                                <p><strong>Top Level Domain</strong>: {country.topLevelDomain}</p>
                                <p><strong>Currencies</strong>: {country.currencies[0].name}</p>
                                <p><strong>Languages</strong>: {country.languages.map((language, i) => <span key={i}>{language.name}{`${ (i < country.languages.length-1) ? ', ':''}`}</span>)}</p>
                            </div>
                        </div>
                        {country.borders &&
                            <div className="row">
                                <div className="col col3 noMargin">
                                    <h3>Border Countries:</h3>
                                </div>
                                <div className="col col9">
                                    <CountryBorderCountries borders={country.borders} />
                                </div>
                            </div>
                        }   
                    </div>
                </div>
            </div>
        </section>
       
    ) : (

        <Spinner />

    );

}

export default CountryDetailSkeleton;