import React from "react";


function CountryListItem({flag, name, population, region, capital, alpha3Code}) {

    return (
        <a href={'/country/' + alpha3Code} >
            <div className="card boxShadow">
                <img src={flag} alt={name} />
            
                <div className="cardBody">
                    <h2>{name}</h2>
                    <p><strong>Population</strong>: {population}</p>
                    <p><strong>Region</strong>: {region}</p>
                    <p><strong>Capital</strong>: {capital}</p>
                </div>
            </div>
        </a>
    );

}

export default CountryListItem;