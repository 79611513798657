import React from 'react';
import CountryListItem from './CountryListItem';
import Spinner from '../shared/Spinner';

function CountryList({countries}){

    return countries ? (
        
        countries.map((country, i) => 
            <CountryListItem key={i} {...country} />
        )
        
    ) : (
        <Spinner />
    );

}

export default CountryList;