import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/shared/Navbar';
import HomePage from './pages/homepage';
import DetailPage from './pages/detail';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import './css/main.css';
import './css/mui.css';

const themes = {
  light: '/css/lightmode.css',
  dark: '/css/darkmode.css',
};

function App() {

  const [darkmode, setDarkmode] = React.useState(false);

  return (
    <Router>
      <ThemeSwitcherProvider defaultTheme="light" insertionPoint="inject-styles-here" themeMap={themes}>
        <Navbar darkmode={darkmode} setDarkmode={setDarkmode} />
      </ThemeSwitcherProvider>
        <Routes>
          <Route path="/" element={<HomePage />}  />
          <Route path="/country/*" element={<DetailPage />}  />
        </Routes>
     
    </Router>
  );

}

export default App;

/*
TODO:
-provider for darkmode

*/
