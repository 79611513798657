import CountrySearch from "../components/homepage/CountrySearch";
import React from "react";
import CountryFilter from "../components/homepage/CountryFilter";
import CountryList from "../components/homepage/CountryList";
import "../css/homepage.css";

const endpointAll = 'https://restcountries.com/v2/all';
const baseUrlRegion = 'https://restcountries.com/v2/continent/';

function HomePage() {
   
    const [countries, setCountries] = React.useState({countries: null, region:'', api:'all'});
    const [searchedCountries, setSearchedCountries] = React.useState('');
    const [searchValue, setSearchValue] = React.useState('');

    React.useEffect(() => {
        if (countries.countries) {
            let filteredCountries = countries.countries.filter((country) => country.name.toLowerCase().startsWith(searchValue.toLowerCase()) );
            setSearchedCountries(filteredCountries);
        }
    }, [searchValue, countries.countries]); 

    React.useEffect(() => {
        if (countries.api === 'region') {
            fetch(baseUrlRegion+countries.region)
            .then((response) => {
                if(response.ok) {
                    return response.json();
                } else {
                    throw new Error("Api not reachable");
                }
               
            })
            .then(data => setCountries({...countries, countries:data}))
            .catch(error => console.log(error) );
            
        } else {

            fetch(endpointAll)
            .then(response => response.json())
            .then(data => setCountries({countries:data, region:'', api: 'all'}));
        }
        
    }, [countries, countries.region]); 
    
    return (

        <>
            <nav className="container">
                <div className="row">
                    <CountrySearch searchValue={searchValue} setSearchValue={setSearchValue} />
                    <CountryFilter countries={countries} setCountries={setCountries} /> 
                </div>
            </nav>
            <main className="container cardContainer">
                {(searchValue !== '') ?  (<CountryList countries={searchedCountries}/>) : (<CountryList countries={countries.countries}/>)}
            </main>
        </>

    );

}

export default HomePage;