import React from "react";
import Button from "../components/shared/Button";
import CountryDetailSkeleton from "../components/detail/CountryDetailSkeleton";
import '../css/detailpage.css';

function DetailPage() {
    
    return (

        <main className="container countryDetailContainer">
            <Button  back={true} boxShadowStrong={true} label="Back" />
            <CountryDetailSkeleton />
        </main>

    );
}

export default DetailPage;