import React from "react";
import Button from "../shared/Button";
import Spinner from "../shared/Spinner";

const baseUrl = 'https://restcountries.com/v2/alpha?codes=';

let urlCreated = false;
let url;
let endpoint;

function CountryBorderCountries({borders}) {

    const [countries, setCountries] = React.useState(null);

    if (borders.length > 1 && !urlCreated) {
        url = borders.join();
        endpoint = baseUrl + url;
        urlCreated = true;
    } else if (borders.length === 1 && !urlCreated) {
        url = borders[0];
        endpoint = baseUrl + url;
        urlCreated = true; 
    }

    React.useEffect(() => {
        fetch(endpoint)
        .then(response => response.json())
        .then(data => setCountries(data));
    }, []);

    return countries ? (

        countries.map( (country, i) =>
            <Button key={i} label={country.name} href={country.alpha3Code} />
        )

    ) : (<Spinner />);

}

export default CountryBorderCountries;